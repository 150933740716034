import {
    change_password,
    update_user,
    getInfo,
    entry_list,
    MatchList,
    upload_code,
    duplicate_check_one,
    create_check_result_user,
    restart_open_code
} from "../../api/ThisInfo.js";
import axios from "axios";
import {
    publicbaseURLs
} from "@/utils/request";
import {
    getToken
} from "@/utils/auth";
export default {
    name: "PersonalInfos",
    data() {
        return {
            UrlApi: publicbaseURLs() + "/image_upload_user", //上传学生证图片地址
            otherUrlApi: publicbaseURLs() + "/upload_other_img", //上传其他图片地址
            UserInfoAll: null,
            EntryListAll: [], //个人比赛列表
            dialogVisible: false, //修改个人信息弹窗状态
            update_info: {}, //修改个人信息表单
            // 修改个人信息表单验证
            rules: {
                username: [{
                    required: true,
                    message: "请输入手机号",
                    trigger: "blur",
                }, ],
                password: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入密码",
                    trigger: "blur"
                }, ],
                specialty: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入专业",
                    trigger: "blur",
                }, ],
                grade: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入年级",
                    trigger: "blur"
                }, ],
                education_background: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入学历",
                    trigger: "blur"
                }, ],
                realname: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入真实姓名",
                    trigger: "blur",
                }],
                nickname: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入昵称",
                    trigger: "blur"
                }, ],
                student_num: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入真实学号",
                    trigger: "blur",
                }, ],
                school_name: [{
                    validator: this.validateContent,
                    trigger: 'blur'
                }, {
                    required: true,
                    message: "请输入真实学校",
                    trigger: "blur",
                }, ],
                email: [{
                        required: true,
                        message: "请输入真实邮箱",
                        trigger: "blur",
                    },
                    {
                        pattern: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/,
                        message: "邮箱格式错误",
                    },
                ],
                faculty_adviser: [{
                    required: true,
                    message: "请输入指导老师",
                    trigger: "blur",
                }]
            },
            PasswordDialogVisible: false, //修改个人密码
            Form: {
                old_password: "",
                new_password: "",
                confirm_password: "",
            }, //修改密码表单
            rulesForm: {
                //修改密码验证
                old_password: [{
                    required: true,
                    message: "请输入旧密码",
                    trigger: "blur",
                }, ],
                new_password: [{
                    required: true,
                    message: "请输入新密码",
                    trigger: "blur",
                }, ],
                confirm_password: [{
                    required: true,
                    message: "请验证新密码是否一致",
                    trigger: "blur",
                }, ],
            },
            FileList: [], //学生证附件
            OtherFileList: [], //其他图片附件
            gradeList: [],
            MathDataList: [], //比赛列表
            code_type_List: [
                "Python",
                "Java",
                "C",
                "C++",
                "SAS",
                "R",
                "Matlab",
                "JavaScript",
                "C#",
                "Go",
            ], //代码类型
            educationBackgroundList: [
                "高中及以下",
                "大专",
                "本科",
                "硕士研究生",
                "博士研究生",
            ],
            code_type: "", //代码类型
            ChangeMathId: null, //选中比赛状态
            FileSourceCodeState: false, //上传源码弹窗列表
            FileSource: [], //源码文件列表,
            PersonalEepetitionRateCard: { //个人重复率卡片
                state: false,
                info: {
                    challenge_title: '',
                    username: '',
                    max_sim: '',
                    mean_sim: '',
                    create_time: '',
                }
            }
        };
    },
    mounted() {
        this.getInfodata();
        this.getEntryList();
        this.getNf();
        this.GetMatchSearch();
    },
    watch: {
        $route(route) {
            if (route.path == "/login") {
                this.$destroy(this.$options.name);
            }
        },
    },
    methods: {
        validateContent(rule, value, callback) {
            // 使用正则表达式检查输入内容是否为空或只包含空格
            const regExp = /^\s*$/;
            if (!value || regExp.test(value)) {
                callback(new Error('内容不能为空或只包含空格'));
            } else {
                callback();
            }
        },
        // 重启在线开发系统
        RestartOnlineDevelopmentSystem() {
            this.$store.commit("app/CHANGE_LOADING", true);
            restart_open_code().then(res => {
                this.$store.commit("app/CHANGE_LOADING", false);
                this.$message({
                    type: "success",
                    message: '重启成功',
                });
            }).catch(() => {
                this.$store.commit("app/CHANGE_LOADING", false);
            })
        },
        // 源文件下载
        SingleitemDow(URL, challenge_title) {
            this.$store.commit("app/CHANGE_LOADING", true);
            axios({
                url: URL,
                method: "get",
                responseType: "blob",
                headers: {
                    Authorization: getToken(),
                },
            }).then((res) => {
                this.$store.commit("app/CHANGE_LOADING", false);
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement("a");
                link.style.display = "none";
                link.href = url;
                link.setAttribute(
                    "download",
                    challenge_title + "." + URL.substr(URL.length - 3)
                ); // 自定义下载文件名（如exemple.txt）
                document.body.appendChild(link);
                link.click();
            }).catch(() => {
                this.$store.commit("app/CHANGE_LOADING", false);
            })
        },
        // 获取比赛列表
        GetMatchSearch() {
            MatchList({
                pageNum: "1",
                pageSize: "100",
            }).then((res) => {
                this.MathDataList = res.data.list;
            });
        },
        getNf() {
            var nfOptionsArray = new Array();
            var years = new Date().getFullYear();
            for (let i = years - 9; i <= years; i++) {
                var anOption = {};
                anOption.dictValue = i + "";
                anOption.dictLabel = i + "";
                nfOptionsArray.push(anOption);
            }
            this.gradeList = nfOptionsArray;
        },
        getEntryList() {
            entry_list().then((res) => {
                if (res.code == 200) {
                    this.EntryListAll = res.data;
                }
            });
        },
        // 获取用户信息
        getInfodata() {
            getInfo().then((res) => {
                this.UserInfoAll = JSON.parse(JSON.stringify(res.data));
                this.$forceUpdate();
            });
        },
        /**
         * 动态设置Dropdown的command
         */
        beforeHandleCommand(flag, command) { // flag为传递的参数
            return {
                'flag': flag,
                'parameter': command
            }
        },
        // 下拉菜单时间
        handleCommand(command) {
            switch (command.flag) {
                case 'ViewACompetitionLogs':
                    this.ViewACompetitionLogs(command.parameter)
                    break;
                case 'ViewACompetitionScorell':
                    this.ViewACompetitionScorell(command.parameter)
                    break;
                case 'GenerateDuplicateCheckResults':
                    this.GenerateDuplicateCheckResults(command.parameter)
                    break;
                case 'ViewDuplicateCheckResults':
                    this.ViewDuplicateCheckResults(command.parameter)
                    break;
                default:
                    this.$message.error('事件失效');
            }
        },
        // 查看用户提交比赛日志
        ViewACompetitionLogs(item) {
            console.log(item, '_______')
        },
        // 查看所有个人比赛分数
        ViewACompetitionScorell(item) {
            this.$router.push({
                path: "/SingleItem",
                query: {
                    id: item.challenge_id,
                    state: 2,
                    // matchstate: item.challenge_status,
                },
            });
        },
        // 个人生成查重结果
        GenerateDuplicateCheckResults(item) {
            create_check_result_user({
                challenge_id: item.challenge_id
            }).then(res => {
                console.log(res, 'res')
                if (res.code == 200) {
                    this.$message({
                        type: "success",
                        message: res.info,
                    });
                }
            })
        },
        // 个人查看查重结果
        ViewDuplicateCheckResults(item) {
            duplicate_check_one({
                username: item.username,
                challenge_id: item.challenge_id
            }).then(res => {
                if (res.code == 200 && res.data.list.length != 0) {
                    this.PersonalEepetitionRateCard.info.challenge_title = item.challenge_title
                    this.PersonalEepetitionRateCard.info.username = res.data.list[0].username
                    this.PersonalEepetitionRateCard.info.max_sim = res.data.list[0].max_sim
                    this.PersonalEepetitionRateCard.info.mean_sim = res.data.list[0].mean_sim
                    this.PersonalEepetitionRateCard.info.create_time = res.data.list[0].create_time
                    this.PersonalEepetitionRateCard.state = true
                } else {
                    this.$message.error('未生成个人查重结果');
                }
                console.log(res, '个人查看查重结果')
            })
        },
        // 登出操作
        LogOut() {
            this.$confirm("此操作将退出登录, 是否继续?", "提示", {
                    confirmButtonText: "退出",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                .then(() => {
                    this.$store
                        .dispatch("FedLogOut", this.loginForm)
                        .then(() => {
                            this.$router.push({
                                path: this.redirect || "/login",
                            });
                        })
                        .catch(() => {
                            this.loading = false;
                        });
                    this.$message({
                        type: "success",
                        message: "退出登录!",
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消退出",
                    });
                });
        },
        // 修改个人信息事件
        ModifyInfo() {
            this.update_info = JSON.parse(JSON.stringify(this.UserInfoAll));
            this.dialogVisible = true;
        },
        // 取消修改个人信息
        cancelModifyInfo() {
            this.update_info = {};
            this.dialogVisible = false;
        },
        // 确定修改个人信息
        ConfirmModifyInfo() {
            delete this.update_info.username;
            this.$refs["update_info"].validate((valid) => {
                if (valid) {
                    update_user({
                        update_info: this.update_info
                    }).then(
                        (res) => {
                            if (res.code == 200) {
                                this.dialogVisible = false;
                                this.$message({
                                    message: "个人信息修改成功",
                                    type: "success",
                                });
                                this.getInfodata();
                            }
                        }
                    );
                } else {
                    return false;
                }
            });
        },
        // 修改密码
        ModifyPassword() {
            this.PasswordDialogVisible = true;
        },
        // 取消修改密码
        cancelModifyPassword() {
            this.Form = {};
            this.PasswordDialogVisible = false;
        },
        // 确认修改密码
        ConfirmModifyPassword() {
            this.$refs["Form"].validate((valid) => {
                if (valid) {
                    change_password(this.Form).then((res) => {
                        if (res.code == 200) {
                            this.$message({
                                message: "密码修改成功",
                                type: "success",
                            });
                            this.PasswordDialogVisible = false;
                            this.$store
                                .dispatch("FedLogOut", {})
                                .then(() => {
                                    this.$router.push({
                                        path: this.redirect || "/login",
                                    });
                                })
                                .catch(() => {});
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        Remove(file, fileList) {
            this.FileList = fileList;
        },
        OtherRemove(file, fileList) {
            this.OtherFileList = fileList
        },
        success() {
            this.$store.commit("app/CHANGE_LOADING", true);
            if (this.UserInfoAll.student_card_path) {
                this.UrlApi = publicbaseURLs() + "/update_user_image";
            } else {
                this.UrlApi = publicbaseURLs() + "/image_upload_user";
            }
            var data = new FormData();
            data.append("file", this.FileList);
            data.append("token", getToken());
            axios({
                    url: this.UrlApi,
                    method: "post",
                    data: data,
                    headers: {
                        Authorization: getToken(),
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.FileList = [];
                        this.getInfodata();
                        this.$router.go(0);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.FileList = [];
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "上传失败",
                    });
                    this.$store.commit("app/CHANGE_LOADING", false);
                    this.FileList = [];
                });
        },
        Othersuccess() {
            this.$store.commit("app/CHANGE_LOADING", true);
            var data = new FormData();
            data.append("file", this.OtherFileList);
            data.append("token", getToken());
            axios({
                    url: this.otherUrlApi,
                    method: "post",
                    data: data,
                    headers: {
                        Authorization: getToken(),
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((res) => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: "success",
                            message: "上传成功",
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.OtherFileList = [];
                        this.getInfodata();
                        this.$router.go(0);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                        this.$store.commit("app/CHANGE_LOADING", false);
                        this.OtherFileList = [];
                    }
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "上传失败",
                    });
                    this.$store.commit("app/CHANGE_LOADING", false);
                    this.OtherFileList = [];
                });
        },
        ChangePreview(file) {
            if (!file) return;
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (
                testmsg !== "jpg" &&
                testmsg !== "png" &&
                testmsg !== "jpeg" &&
                testmsg !== "JPG" &&
                testmsg !== "PNG" &&
                testmsg !== "JPEG"
            ) {
                this.$refs.PreviewUploads.clearFiles();
                this.$message({
                    message: "上传学生证图片只能是 .jpg,.png,.jpeg,.JPG,.PNG,JPEG格式!",
                    type: "warning",
                });
                return false;
            }
            if (!isLt2M) {
                this.$refs.PreviewUploads.clearFiles();
                this.$message({
                    message: "上传学生证图片大小不能超过 2MB!",
                    type: "warning",
                });
                return false;
            }
            this.FileList = file.raw;
        },
        ChangeOtherPreview(file) {
            if (!file) return;
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (
                testmsg !== "jpg" &&
                testmsg !== "png" &&
                testmsg !== "jpeg" &&
                testmsg !== "JPG" &&
                testmsg !== "PNG" &&
                testmsg !== "JPEG"
            ) {
                this.$refs.PreviewOtherUploads.clearFiles();
                this.$message({
                    message: "上传学生证图片只能是 .jpg,.png,.jpeg,.JPG,.PNG,JPEG格式!",
                    type: "warning",
                });
                return false;
            }
            if (!isLt2M) {
                this.$refs.PreviewOtherUploads.clearFiles();
                this.$message({
                    message: "上传学生证图片大小不能超过 2MB!",
                    type: "warning",
                });
                return false;
            }
            this.OtherFileList = file.raw;
        },
        // 上传源码按钮
        UploadFileSourceCode() {
            this.FileSourceCodeState = true;
            this.ChangeMathId = null;
        },
        // 取消源码上传
        cancelUploadSourceCode() {
            this.FileSourceCodeState = false;
            this.ChangeMathId = null;
            this.FileSource = [];
        },
        // 确定上传源码
        ConfirmUploadSourceCode() {
            this.$store.commit("app/CHANGE_LOADING", true);
            if (!this.ChangeMathId) {
                this.$message({
                    type: "error",
                    message: "请选择需要上传的比赛",
                });
                return;
            }
            if (!this.code_type) {
                this.$message({
                    type: "error",
                    message: "请选择代码类型",
                });
                return;
            }
            if (!this.FileSource.length > 0) {
                this.$message({
                    type: "error",
                    message: "请选择需要上传的比赛源码文件",
                });
                return;
            }
            var data = new FormData();
            data.append("file", this.FileSource[0].raw);
            upload_code(data, this.ChangeMathId, this.code_type)
                .then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.msg,
                        });
                        this.cancelUploadSourceCode();
                        this.$store.commit("app/CHANGE_LOADING", false);
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                        });
                        this.cancelUploadSourceCode();
                        this.$store.commit("app/CHANGE_LOADING", false);
                    }
                })
                .catch((err) => {
                    this.$store.commit("app/CHANGE_LOADING", false);
                    this.$message({
                        type: "error",
                        message: err.msg,
                    });
                });
        },
        FileSourceChange(file) {
            if (!file) return;
            var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
            const isLt2M = file.size / 1024 / 1024 < 3;
            if (
                testmsg !== "zip" &&
                testmsg !== "ZIP" &&
                testmsg !== "RAR" &&
                testmsg !== "rar"
            ) {
                this.$refs.UploadsDemo.clearFiles();
                this.$message({
                    message: "上传文件只能是 .zip.ZIP、RAR、rar格式!",
                    type: "warning",
                });
                return false;
            }
            if (!isLt2M) {
                this.$refs.UploadsDemo.clearFiles();
                this.$message({
                    message: "上传文件大小不能超过 3MB!",
                    type: "warning",
                });
                return false;
            }
            this.FileSource = [file];
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
    },
};
